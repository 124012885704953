import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Aktualnosci = () => {
  // const url = process.env.GATSBY_STRAPI_API_URL
  // const apiRoute = "/api/blogs"
  // const bearer = process.env.GATSBY_STRAPI_TOKEN
  // const config = {
  //   headers: {
  //     Bearer: bearer,
  //   },
  // }
  // const apiRequestUrl = url + apiRoute

  const data = useStaticQuery(graphql`
    query ArticlesQuery {
      allStrapiBlog(sort: { fields: createdAt, order: DESC }) {
        nodes {
          Date(formatString: "DD MMMM, YYYY", locale: "pl")
          Slug
          Title
          Description
          Content {
            data {
              Content
            }
          }
        }
      }
    }
  `)
  // console.log(data)

  const articles = data?.allStrapiBlog?.nodes
  return (
    <Layout>
      <Seo title="Aktualności" />

      <div className="bg-white px-4 pt-16 pb-20 mb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
        <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Aktualności
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
              <p className="text-xl text-gray-500">
                Bądź na bieżąco z naszymi działaniami.
              </p>
            </div>
          </div>
          <div className="mt-6 grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-12 lg:gap-y-12">
            {articles.length > 0 ? (
              articles.map(post => (
                <div key={post.Slug}>
                  <p className="text-sm text-gray-500">
                    <time dateTime={post.Date}>{post.Date}</time>
                  </p>
                  <a href="#" className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">
                      {post.Title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                      {post.Description}
                    </p>
                  </a>
                  <div className="mt-3">
                    <Link
                      to={post.Slug}
                      className="text-base bg-primary rounded-md font-semibold text-bg p-2 hover:text-red-500"
                    >
                      Przeczytaj wpis
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <span>Brak aktualności.</span>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Aktualnosci
